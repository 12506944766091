import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MobileShopHeaderConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { ActionLink, Icon, IconName } from "halifax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import "./styles.scss";

export interface IMobileShopHeaderProps
  extends MobileShopHeaderConnectorProps,
    RouteComponentProps {}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const { selectedListing, fromDate, untilDate, numAdults, numChildren } =
    props;

  const title = selectedListing?.listing?.content?.name || "Stay";

  return (
    <Box
      className="mobile-shop-header"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      borderBottom=".5px solid var(--grey-11)"
      height="55px"
      style={{ backgroundColor: "white" }}
      paddingX="18px"
    >
      <ActionLink
        onClick={() => {
          // TODO: Handle back button click in follow-up PR
        }}
        content={<FontAwesomeIcon icon={faChevronLeft} />}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">{title}</Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#505050",
          }}
        >
          {fromDate &&
            untilDate &&
            `${dayjs(fromDate).format("MMM DD")} - ${dayjs(untilDate).format(
              "MMM DD"
            )}, `}
          {numAdults + numChildren.length > 1
            ? `${numAdults + numChildren.length} Travelers`
            : `${numAdults + numChildren.length} Traveler`}
        </Typography>
      </Box>

      <ActionLink
        onClick={() => {
          // TODO: Handle edit button click in follow-up PR
        }}
        content={<Icon name={IconName.B2BEditPencil} className="edit-icon" />}
      />
    </Box>
  );
};

import React, { useEffect } from "react";
import dayjs from "dayjs";
import { Box } from "@material-ui/core";
import { BookingSuccessModalConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { VacationRentalConfirmation, useDeviceTypes } from "halifax";
import {
  PATH_STAYS,
  PATH_VACATION_RENTALS_BOOK,
} from "../../../../utils/paths";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import "./styles.scss";
import {
  PORTAL_TITLE,
  BOOKING_CONFIRMATION_TITLE,
} from "../../../../lang/textConstants";
import { VR_COPY } from "./textConstants";

export interface IBookingSuccessModalProps
  extends BookingSuccessModalConnectorProps,
    RouteComponentProps {
  isTreesModalExperiment: boolean;
}

export const BookingSuccessModal = (props: IBookingSuccessModalProps) => {
  const {
    history,
    resetBookState,
    earnString,
    confirmationEmail: email = "",
    isTreesModalExperiment,
    vrReservation,
    selectedHome,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    document.title = BOOKING_CONFIRMATION_TITLE;

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  // TODO: since 'reservation' is not preserved on refresh, it would cause undefined error;
  // discuss with PM regarding what the expected behaviour is (e.g.: handle it properly with error modals, or preserve this state).

  if (
    !vrReservation &&
    history.location.pathname === PATH_VACATION_RENTALS_BOOK
  ) {
    history.push(PATH_STAYS);
    return null;
  }

  const checkIn = dayjs(vrReservation?.stayDates.from);
  const checkOut = dayjs(vrReservation?.stayDates.until);

  const treesModalText = {
    treesModalHeader: textConstants.TREES_MODAL_HEADER,
    treesModalTitle: textConstants.TREES_MODAL_TITLE,
    treesModalSubtitle: textConstants.TREES_MODAL_SUBTITLE,
    treesModalImgLocation: textConstants.TREES_MODAL_IMG_LOCATION,
    treesModalLinkCopy: textConstants.TREES_MODAL_CTA_TEXT,
    treesModalBoldedLinkCopy: textConstants.TREES_BOLDED_MODAL_CTA_TEXT,
  };

  useEffect(() => {
    const feedbackDiv =
      window.__mclean_env__.ENV === "production"
        ? document.getElementById("prod-capital-one-travel-web-embed")
        : document.getElementById("test-capital-one-travel-web-embed");
    if (!document.getElementById("embeddedIframe14203")) {
      feedbackDiv?.classList.add("no-background");
    } else {
      if (
        feedbackDiv?.classList.length &&
        feedbackDiv?.classList.contains("no-background")
      ) {
      }
      feedbackDiv?.classList.remove("no-background");
    }
  }, [document.getElementById("embeddedIframe14203")]);

  return (
    <Box
      className={clsx("vacation-rental-booking-success-container", {
        mobile: matchesMobile,
      })}
    >
      {vrReservation ? (
        <VacationRentalConfirmation
          bannerCopy={VR_COPY}
          selectedHome={vrReservation.listing}
          listingCollection={selectedHome?.listing.listingCollection}
          title={textConstants.TITLE(email)}
          subtitle={textConstants.SUBTITLE(vrReservation.id.value)}
          nextHeader={textConstants.WHATS_NEXT_HEADER}
          infoCardTitles={{
            flightsTitle: textConstants.FLIGHTS_TITLE,
            flightsDescription: textConstants.FLIGHTS_DESCRIPTION,
            carsTitle: textConstants.CARS_TITLE,
            carsDescription: textConstants.CARS_DESCRIPTION,
          }}
          checkIn={checkIn.toDate()}
          checkOut={checkOut.toDate()}
          onFlightsClick={() => {
            history.push("/flights");
            resetBookState();
          }}
          onCarsClick={() => {
            history.push("/cars");
            resetBookState();
          }}
          isMobile={matchesMobile}
          earnString={earnString}
          displayTrees={isTreesModalExperiment}
          treesModalText={treesModalText}
          isProdEnv={window.__mclean_env__.ENV === "production"}
          cancellationPolicy={vrReservation.cancellationPolicy}
        />
      ) : undefined}
    </Box>
  );
};

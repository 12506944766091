import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { Prices } from "redmond";
import { VacationRentalShopConnectorProps } from "./container";
import { OccupancySelector } from "../OccupancySelector/component";
import { ActionButton, pluralize } from "halifax";
import "./styles.scss";
import { ConnectedDateSelector } from "../DateSelector/container";
import { goToCheckout } from "../../utils/queryStringHelpers";

// Display context of the ShopCta
export enum IShopCtaVariant {
  DESKTOP = "DESKTOP",
  DESKTOP_MAP = "DESKTOP_MAP",
  CTA_BUTTON_ONLY = "BUTTON_ONLY",
  PRICING_ONLY = "PRICING_ONLY",
}

export interface IShopCtaProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {
  variant: IShopCtaVariant;
}

export const ShopCta = (props: IShopCtaProps) => {
  const {
    variant,
    selectedListing,
    selectedRewardsAccount,
    lengthOfStay,
    numAdults,
    numChildren,
    numPets,
    setOccupancyCounts,
    history,
  } = props;

  if (!selectedListing) {
    return null;
  }

  const { availability } = selectedListing;

  const { rate } = availability;

  const { price } = rate || {};

  if (!price) {
    return null;
  }
  const { nightly, total, rentTotal, taxes, fees } = price;

  const formatNumber = (amount: number, currencySymbol: string) => {
    return `${currencySymbol}${amount}`;
  };

  const formatPrice = (price: Prices) => {
    return `${price?.fiat?.currencySymbol}${price?.fiat?.value}`;
  };

  const formatRewardsPrice = (price: Prices) => {
    const rewardsPrice = selectedRewardsAccount
      ? price.rewards[selectedRewardsAccount]
      : null;

    if (!rewardsPrice) {
      return "";
    } else {
      let miles = rewardsPrice?.value;
      let milesWithComma = miles.toLocaleString("en-US"); // Add comma to the miles amount. ie: 30000 -> 30,000
      return `/ ${milesWithComma} ${rewardsPrice?.currency.toLocaleLowerCase(
        "en-US"
      )}`;
    }
  };

  const taxesTotal = taxes
    .map((x) => x.amount?.fiat?.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const feesTotal = fees
    .map((x) => x.amount?.fiat?.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const taxesAndFeesTotal = taxesTotal + feesTotal;

  const nightlyPriceDisplay = formatPrice(nightly);
  const rentPriceDisplay = formatPrice(rentTotal);
  const totalPriceDisplay = formatPrice(total);
  const milesPriceDisplay = formatRewardsPrice(total);
  const totalTaxesAndFeesDisplay = formatNumber(
    taxesAndFeesTotal,
    total.fiat?.currencySymbol
  );

  const renderPricingDetails = () => {
    return (
      <Box>
        <Typography
          style={{ fontWeight: 600, fontSize: "16px", color: "#141414" }}
        >
          {nightlyPriceDisplay}
          <span style={{ fontSize: "12px" }}> per night</span>
        </Typography>
        {lengthOfStay && (
          <Typography style={{ fontSize: "12px", color: "#141414" }}>
            {totalPriceDisplay} for {lengthOfStay}{" "}
            {pluralize(lengthOfStay, "night", "nights")} {milesPriceDisplay}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box className="shop-cta">
      {variant === IShopCtaVariant.DESKTOP && (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            style={{ gap: "12px" }}
          >
            {renderPricingDetails()}
            <Box display="flex" flexDirection="column" style={{ gap: "12px" }}>
              <ConnectedDateSelector />
              <OccupancySelector
                onSave={setOccupancyCounts}
                guests={{
                  adults: numAdults,
                  children: numChildren,
                  pets: numPets,
                  rooms: 1,
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            style={{ gap: "12px" }}
          >
            <Typography variant="h6" style={{ color: "#141414" }}>
              Checkout breakdown
            </Typography>
            <Divider style={{ height: "0.5px" }} />
            <Box display="flex" justifyContent="space-between">
              {lengthOfStay && (
                <Typography style={{ fontSize: "14px", color: "#676868" }}>
                  Home rental x {lengthOfStay}{" "}
                  {pluralize(lengthOfStay, "night", "nights")}
                </Typography>
              )}
              <Typography style={{ fontSize: "14px", color: "#676868" }}>
                {rentPriceDisplay}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ fontSize: "14px", color: "#676868" }}>
                Taxes and fees
              </Typography>
              <Typography style={{ fontSize: "14px", color: "#676868" }}>
                {totalTaxesAndFeesDisplay}
              </Typography>
            </Box>
            <Divider style={{ height: "0.5px" }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" style={{ color: "#141414" }}>
                Total
              </Typography>

              <Typography variant="h6" style={{ color: "#141414" }}>
                {totalPriceDisplay}{" "}
                <span style={{ fontWeight: 400, color: "#676868" }}>
                  {milesPriceDisplay}
                </span>
              </Typography>
            </Box>
            <ActionButton
              className="cta-button"
              buttonClassName="cta-button-inner"
              onClick={() => goToCheckout({ history })}
              message={
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Continue for {nightlyPriceDisplay} per night
                </Typography>
              }
            />
          </Box>
        </Box>
      )}
      {variant === IShopCtaVariant.CTA_BUTTON_ONLY && (
        <ActionButton
          className="cta-button-mobile"
          buttonClassName="cta-button-inner"
          onClick={() => goToCheckout({ history })}
          message={
            <Typography
              style={{ fontSize: "16px", fontWeight: "600", color: "white" }}
            >
              Continue for {nightlyPriceDisplay} per night
            </Typography>
          }
        />
      )}
      {variant === IShopCtaVariant.PRICING_ONLY && renderPricingDetails()}
    </Box>
  );
};

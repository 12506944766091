import React, { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import {
  DatePickerButton,
  DateRangePicker,
  DesktopPopupModal,
  PickerType,
} from "halifax";
import "./styles.scss";
import dayjs from "dayjs";
import { DateSelectorConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";

export interface IDateSelector
  extends DateSelectorConnectorProps,
    RouteComponentProps {
  classes?: string[];
  hideSeparator?: boolean;
  hasMissingSearchInfoError?: boolean;
}

export const DateSelector = (props: IDateSelector) => {
  const {
    fromDate,
    untilDate,
    hideSeparator,
    hasMissingSearchInfoError,
    setFromDate,
    setUntilDate,
    fetchVacationRentalShop,
    history,
  } = props;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  const handleClickDone = () => {
    setFromDate(from);
    setUntilDate(until);
    setShowDatePicker(false);
    fetchVacationRentalShop(history, {});
  };

  return (
    <Box className="date-selector">
      <Box>
        <DatePickerButton
          startDate={fromDate}
          endDate={untilDate}
          startLabel={"Check-in"}
          endLabel={"Checkout"}
          classes={["date-picker"]}
          hideSeparator={hideSeparator}
          onClick={() => setShowDatePicker((showDatePicker) => !showDatePicker)}
          dateFormat={"ddd, MMM D"}
          hasMissingSearchInfoError={hasMissingSearchInfoError}
        />
      </Box>
      {showDatePicker && (
        <DesktopPopupModal
          open={showDatePicker}
          contentClassName="date-picker-content"
          onClose={() => setShowDatePicker((showDatePicker) => !showDatePicker)}
          invisibleBackdrop={false}
          headerElement={
            <Typography style={{ padding: "40px 40px 10px" }}>
              Select the check-in and checkout dates for your stay
            </Typography>
          }
        >
          <DateRangePicker
            className="b2b"
            minAllowedDate={dayjs().toDate()}
            maxAllowedDate={dayjs().add(1, "year").toDate()}
            minNumDays={1}
            currency={""}
            startDate={from}
            endDate={until}
            setStartDate={setFrom}
            setEndDate={setUntil}
            months={[]}
            priceTags={[]}
            pickerType={PickerType.RANGE}
            columnView={false}
          />

          <Box
            display="flex"
            paddingTop="20px"
            paddingX="40px"
            marginBottom="40px"
            justifyContent="flex-end"
          >
            <Button
              onClick={handleClickDone}
              disabled={!from || !until}
              className="select-dates-button"
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </DesktopPopupModal>
      )}
    </Box>
  );
};

export const apiVersionPrefix: string = "/api/v0";
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const vacationRentalsApiPrefix: string = `${apiVersionPrefix}/homes`;
export const vacationRentalShopApiPrefix = `${vacationRentalsApiPrefix}/shop`;
export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;
export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;
export const paymentMethodsApiPrefix = `${apiVersionPrefix}/paymentMethods`;
export const passengersApiPrefix: string = `${apiVersionPrefix}/passengers`;

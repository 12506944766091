import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setContactInfo,
  fetchApplicableTravelWalletItems,
  scheduleVacationRentalPriceQuote,
  setUserSelectedPassengerIds,
  scheduleVacationRentalsBook,
  addMainGuest,
} from "../../actions/actions";

import { IStoreState } from "../../../../reducers/types";
import { DesktopVacationRentalsBookWorkflow } from "./component";
import {
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  isTravelerStepComplete,
  isContactStepComplete,
  getPriceQuoteErrors,
  getConfirmationDetailsError,
  getVacationRentalsPriceQuote,
  areAllVacationRentalsStepsCompletedInCheckout,
  getVacationRentalsIsBookingValid,
  getVacationRentalConfirmationDetails,
  getVacationRentalsPollPriceQuoteCallState,
  getUserSelectedTraveler,
  getAddMainGuestCallState,
  getCombinedBookingSteps,
} from "../../reducer";
import { getRewardsAccountWithLargestEarnForBanner } from "../../../rewards/reducer";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";
import { setNotifyIfShopAndBookPriceDifferFlag } from "../../../shop/actions/actions";
import { fetchTravelWalletCreditHistory } from "../../../travel-wallet/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    combinedBookingSteps: getCombinedBookingSteps(state),
    reservation: getVacationRentalConfirmationDetails(state),
    email: getConfirmationEmail(state),
    phoneNumber: getConfirmationPhoneNumber(state),
    isTravelerStepComplete: isTravelerStepComplete(state),
    isContactStepComplete: isContactStepComplete(state),
    priceQuoteErrors: getPriceQuoteErrors(state),
    confirmationDetailsErrors: getConfirmationDetailsError(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
    vacationRentalPriceQuote: getVacationRentalsPriceQuote(state),
    isBookingValid: getVacationRentalsIsBookingValid(state),
    allStepsCompleted: areAllVacationRentalsStepsCompletedInCheckout(state),
    priceQuote: getVacationRentalsPriceQuote(state),
    pollPriceQuoteCallState: getVacationRentalsPollPriceQuoteCallState(state),
    userSelectedTraveler: getUserSelectedTraveler(state),
    addMainGuestCallState: getAddMainGuestCallState(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
  scheduleVacationRentalPriceQuote,
  scheduleVacationRentalsBook,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
  setUserSelectedPassengerIds,
  addMainGuest,
  setNotifyIfShopAndBookPriceDifferFlag: setNotifyIfShopAndBookPriceDifferFlag,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopVacationRentalsBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopVacationRentalsBookWorkflow = withRouter(
  connector(DesktopVacationRentalsBookWorkflow)
);

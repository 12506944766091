import { ListingId, ListingSearchResult } from "redmond";
import * as actionTypes from "./constants";
import * as H from "history";
import { VacationRentalShopCallError } from "../reducer";

export interface IFetchVacationRentalShop {
  type: actionTypes.FETCH_VACATION_RENTAL_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallVRAvailability?: boolean;
  };
}

export const fetchVacationRentalShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallVRAvailability?: boolean;
  }
): IFetchVacationRentalShop => ({
  type: actionTypes.FETCH_VACATION_RENTAL_SHOP,
  history,
  options,
});

export interface ISetVacationRentalShopCallStateFailed {
  type: actionTypes.SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED;
  vrShopCallError: VacationRentalShopCallError;
}

export const setVacationRentalShopCallStateFailed = (
  vrShopCallError: VacationRentalShopCallError
): ISetVacationRentalShopCallStateFailed => ({
  type: actionTypes.SET_VACATION_RENTAL_SHOP_CALL_STATE_FAILED,
  vrShopCallError,
});
export interface ISelectHome {
  type: actionTypes.SELECT_HOME;
  home: ListingSearchResult;
}

export const selectHome = (home: ListingSearchResult): ISelectHome => ({
  type: actionTypes.SELECT_HOME,
  home,
});

export interface ISetSelectedListingId {
  type: actionTypes.SET_SELECTED_LISTING_ID;
  id: ListingId;
}

export const setSelectedListingId = (id: ListingId): ISetSelectedListingId => ({
  type: actionTypes.SET_SELECTED_LISTING_ID,
  id,
});

export interface ISetNotifyIfShopAndBookPriceDifferFlag {
  type: actionTypes.SET_NOTIFY_IF_SHOP_AND_BOOK_PRICE_DIFFER_FLAG;
  notify: boolean;
}

export const setNotifyIfShopAndBookPriceDifferFlag = (
  notify: boolean
): ISetNotifyIfShopAndBookPriceDifferFlag => ({
  type: actionTypes.SET_NOTIFY_IF_SHOP_AND_BOOK_PRICE_DIFFER_FLAG,
  notify,
});

export type VacationRentalShopActions =
  | IFetchVacationRentalShop
  | ISetVacationRentalShopCallStateFailed
  | ISelectHome
  | ISetSelectedListingId
  | ISetNotifyIfShopAndBookPriceDifferFlag;

import React from "react";
import {
  B2BButton,
  DesktopPopupModal,
  Icon,
  IconName,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import { Box, Typography } from "@material-ui/core";
import { GuestsSelection } from "redmond";
import "./styles.scss";

export interface IOccupancySelection {
  onSave: (guests: Omit<GuestsSelection, "rooms">) => void;
  guests: GuestsSelection;
}

export const OccupancySelector = (props: IOccupancySelection) => {
  const { guests, onSave } = props;
  const { adults, children } = guests;
  const numGuests = adults + children.length;

  const [showPassengerCountPicker, setShowPassengerCountPicker] =
    React.useState(false);

  return (
    <Box className="occupancy-selection">
      <B2BButton
        style={{ height: "50px" }}
        variant="traveler-selector"
        onClick={() => setShowPassengerCountPicker(true)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
            <Icon name={IconName.B2BUser} className="icon-user" />
            <Typography style={{ color: "#013D58" }}>
              {numGuests} guests
            </Typography>
          </Box>
          <Icon name={IconName.B2BEditPencil} className="icon-user" />
        </Box>
      </B2BButton>

      {showPassengerCountPicker && (
        <DesktopPopupModal
          open={showPassengerCountPicker}
          className="desktop-passenger-count-picker-popup"
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setShowPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={"Edit travelers"}
        >
          <PassengerCountPicker
            setPassengerCounts={(counts: PassengerCountPickerType) => {
              const { adults, children } = counts as GuestsSelection;
              onSave({ adults, children });
            }}
            onClickApply={() => setShowPassengerCountPicker(false)}
            counts={guests}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};

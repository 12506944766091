import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { fetchVacationRentalShop } from "../../actions/actions";
import { withRouter } from "react-router";
import { MobileShopHeader } from "./component";
import {
  getFromDate,
  getUntilDate,
  getNightCount,
  getAdultsCount,
  getChildren,
  getPetsCount,
} from "../../../search/reducer";
import { getVacationRentalShopSelectedListing } from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedListing: getVacationRentalShopSelectedListing(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    lengthOfStay: getNightCount(state),
    numAdults: getAdultsCount(state),
    numChildren: getChildren(state),
    numPets: getPetsCount(state),
  };
};

const mapDispatchToProps = {
  fetchVacationRentalShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopHeader = withRouter(
  connector(MobileShopHeader)
);

import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { VacationRentalShopCallState } from "../index";
import {
  ITrackingProperties,
  ObfuscatedAddress,
  ViewedVacationRentalDetailsProperties,
} from "redmond";
import { getSelectedAccount } from "../../../rewards/reducer";
import { getAdultsCount, getChildrenCount } from "../../../search/reducer";

export const getVacationRentalShopSelectedListing = (state: IStoreState) =>
  state.vacationRentalShop.selectedHome;

export const getNotifyIfShopAndBookPriceDiffer = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalNotifyIfShopAndBookPriceDiffer;

export const getVacationRentalShopSelectedListingId = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalListingId;

export const getVacationRentalShopCallState = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallState;

export const getVacationRentalShopCallError = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallError;

export const hasVacationRentalShopFailed = createSelector(
  getVacationRentalShopCallState,
  (vrShopCallState) => {
    return vrShopCallState === VacationRentalShopCallState.Failed;
  }
);

export const getViewedVacationRentalDetailsProperties = createSelector(
  getSelectedAccount,
  getAdultsCount,
  getChildrenCount,
  getVacationRentalShopSelectedListing,
  (
    account,
    adultsCount,
    childrenCount,
    listing
  ): ITrackingProperties<ViewedVacationRentalDetailsProperties> => {
    return {
      properties: {
        account_type_selected: account?.productDisplayName || "",
        ...listing?.listing.trackingProperties?.properties,
        account_use_type: account?.accountUseType,
        customer_account_role: account?.customerAccountRole,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        number_of_photos: listing?.listing.content.media.length,
        home_city: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.city,
        home_host_name: listing?.listing.hostInfo?.hostName,
        home_id: listing?.listingId.id,
        home_private_host_id: listing?.listing.privateHostInfo?.id,
        home_private_host_name: listing?.listing.privateHostInfo?.name,
        home_state: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.state,
        adults_count: adultsCount,
        children_count: childrenCount,
        delegated_to: "",
        first_launch: false,
        has_credits: false,
        credit_balance: 0,
        vx_statement_credit_balance: 0,
      },
      encryptedProperties: [
        listing?.listing.trackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);
